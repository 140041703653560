import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['insert', 'param', 'spinner', 'spinningIcon']
    static values = {
      insert: String,
      url: String,
      loadUponPageLoad: Boolean,
      method: String,
      requestType: String,
    }

    // Add the following to a div
    // data-controller="async-content-loader"
    // data-async-content-loader-url-value="## URL ##"
    // data-async-content-loader-method-value="## METHOD ##"     if not provided -> DEFAULT: GET

    // add 'data-async-content-loader-load-upon-page-load-value' as datamap to call load for page load --> load_async

    // Element which triggers AJAX
    // Add data-action="change->async-content-loader#load"

    // Optional: Add a target where the element should be inserted.
    // data-async-content-loader-target='insert'
    // If insert should happen outside of the controller, pass in an ID value with a hashtag at the beginning
    // data-async-content-loader-insert-value='#element_id'
    // If insert should happen after the element, pass in after
    // data-async-content-loader-insert-value='after'
    // If no insert-target is set, it will be inserted on the element where the controller is defined


    // to add parameters to the url, add to a input field
    // data-async-content-loader-target="param".
    // It will send the following as param: input[name]: value
    // to define a different attrribute name for submission, define own value like this
    // important. define on the input element
    // data-async-content-loader-param-name='#YOUR NAME'

    // to add a spinner on submission
    // data-async-content-loader-target="spinner"

    // For a reload button add the following
    // <i class="fas fa-sync-alt mt-3"
    //   data-action="click->async-content-loader#load"
    //   data-async-content-loader-target="spinningIcon"></i>

    connect() {
      // if statement to prevent loading upon connect
      if (this.loadUponPageLoadValue) {
        this.load()
      }
    }

    load() {
      this.addSpinner()
      this.spinningIconStart()

      let url = this.urlValue
      // let authenticity_token = document.getElementsByName('csrf-token')[0].content
      const method = (this.methodValue || 'GET').toUpperCase()

      const options = {
        headers: { 'X-CSRF-Token': this.authenticityToken,
          'Content-Type': 'application/x-www-form-urlencoded' },
        method: method,
      }

      const params = {}
      let inputElValue

      for (let i = 0; i < this.paramTargets.length; i++ ) {
        if (this.paramTargets[i].getAttribute('type') == 'checkbox') {
          inputElValue = this.paramTargets[i].checked
        } else {
          inputElValue = this.paramTargets[i].value
        }

        if (this.paramTargets[i].dataset.asyncContentLoaderParamName != undefined) {
          // if data attribute data-async-content-loader-param-name is defined
          params[this.paramTargets[i].dataset.asyncContentLoaderParamName] = inputElValue
        } else {
          params[this.paramTargets[i].getAttribute('name')] = inputElValue
        }
      }

      if (method == 'GET') {
        url = url + '?' + this.stringifyParams(params)
      } else {
        options.body = this.stringifyParams(params)
      }

      if (this.requestTypeValue == 'turbo') {
        const formData = new FormData()
        Object.entries(params).forEach(([key, val]) => {
          formData.append(key, val)
        })
        fetch(url, {
          method: method,
          body: formData,
          headers: {
            'Accept': 'text/vnd.turbo-stream.html',
            'X-CSRF-Token': document.querySelectorAll('[name="csrf-token"]')[0].content,
          },
        })
          .then((r) => r.text())
          .then((html) => Turbo.renderStreamMessage(html))
      } else {
        fetch(url, options )
          .then((response) => response.text())
          .then(function(html) {
            if (this.hasInsertTarget) {
              this.insertTarget.innerHTML = html
            } else if (this.insertValue != '' && this.insertValue[0] === '#') {
              document.querySelector(this.insertValue).innerHTML = html
            } else if (this.insertValue == 'after') {
              this.element.insertAdjacentHTML('afterEnd', html)
            } else {
              this.element.innerHTML = html
            }
            this.fireEventForLoadCategory()
            this.removeSpinner()
            this.spinningIconStop()
          }.bind(this))
      }
    }

    stringifyParams(params) {
      const searchParams = new URLSearchParams()
      Object.keys(params).forEach((key) => searchParams.append(key, params[key]))
      return searchParams.toString()
    }

    fireEventForLoadCategory() {
      const event = new CustomEvent('open-node', {
        detail: {
          name: 'open-node',
        },
      })
      window.dispatchEvent(event)
    }

    clickNode(e) {
      const event = new CustomEvent('select-node', {
        detail: {
          name: e.target.name, value: e.currentTarget.dataset,
          currentElement: e.currentTarget,
        },
      })
      window.dispatchEvent(event)
    }

    addSpinner() {
      if (this.hasSpinnerTarget) {
        this.spinnerTarget.classList.add('whirl', 'double-up')
      }
    }

    removeSpinner() {
      if (this.hasSpinnerTarget) {
        this.spinnerTarget.classList.remove('whirl', 'double-up')
      }
    }

    spinningIconStart() {
      if (this.hasSpinningIconTarget) {
        this.spinningIconTarget.classList.add('text-primary', 'spin')
      }
    }

    spinningIconStop() {
      if (this.hasSpinningIconTarget) {
        this.spinningIconTarget.classList.remove('text-primary', 'spin')
      }
    }

    get authenticityToken() {
      return document.querySelector('meta[name=\'csrf-token\']').getAttribute('content')
    }
}
